import React, { PureComponent } from 'react';
import { Row, Col } from 'antd';
import { Card, Spin } from 'antd';
import DPlayer from 'react-dplayer';


type videoSource = {
  name: string
  url: string
  type: 'hls'
}
type State = {
  init: boolean
  autoPlay?: boolean
  videos?: videoSource[]
  defaultQuality?: number
}

class App extends PureComponent<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      init: false
    }
  }
  componentDidMount() {
    const searchParams = new URL(document.location.href).searchParams
    const videosSearch = String(searchParams.get("video"))
    const videosDeoded = atob(videosSearch)
    const videos: videoSource[] = JSON.parse(videosDeoded)
    const autoPlay = searchParams.get("autoplay") === "true"
    const defaultQuality = Math.min(videos.length - 1, 1)
    this.setState({
      init: true,
      autoPlay,
      defaultQuality,
      videos
    })
  }


  render() {

    return (
      <div className="App" style={{ height: "100%" }}>
        {(() => {
          if (this.state.init) {
            return <DPlayer
              style={{ height: "100%", width: "100%" }}
              screenshot={true}
              options={{
                autoplay: this.state.autoPlay,
                // volume: this.props.defaultMute ? 0 : undefined,
                // volume:0,
                video: {
                  quality: this.state.videos,
                  defaultQuality: this.state.defaultQuality,
                }
              }}
            />
          } else {
            return "init"
          }
        })()}

      </div >
    );

  }
}


export default App;
